import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Ad from '@ifeng-fe/ui_pc_ad';
import styles from './index.css';
import errorBoundary from '@ifeng-fe/errorBoundary';
import Chip from 'Chip';

/**
 * for this page
 */
import Tit from '../tit';
import Slide from '../slide';
import OneArticle from '../oneArticle';
import CONFIG from '../config';
import { getCommentCount } from '../../../../../services/api';

class Column extends React.PureComponent {
    static propTypes = {
        content: PropTypes.object,
        eachKey: PropTypes.string,
    };

    state = {
        news: this.props.content.news || [],
        count: [],
    };

    /**
     * 获取评论数
     */
    async componentDidMount() {
        try {
            const { news } = this.state;
            const docUrl = news.map(item => item.commentUrl || '');
            const counts = await getCommentCount(docUrl);
            let count = counts.map(item => item.count);

            // 兼容commentUrl都是空的情况
            if (Array.isArray(count) && count.length === 0) {
                count = new Array(news.length).fill(0);
            }

            this.setState({ count });
        } catch (e) {
            console.log(e);
        }
    }

    render() {
        const { content, eachKey } = this.props;
        const { tit, slide, ad } = content;
        const { news, count } = this.state;

        const slideChipID = CONFIG.MAINSILDECHIPID[eachKey].id;
        const slideChipTitle = CONFIG.MAINSILDECHIPID[eachKey].title;

        const listChipID = CONFIG.MAINLISTCHIPID[eachKey].id;
        const listChipTitle = CONFIG.MAINLISTCHIPID[eachKey].title;

        return (
            <Fragment>
                <div className={styles.con}>
                    <Tit txt={tit} />
                    <Chip
                        id={slideChipID}
                        type="recommend"
                        title={slideChipTitle}
                        groupName="正文"
                        content={slide}
                        translate="handleFoodMainSlide">
                        <Slide />
                    </Chip>
                    {count.length > 0 ? (
                        <Chip
                            id={listChipID}
                            type="recommend"
                            title={listChipTitle}
                            groupName="正文"
                            content={news}
                            translate="handleFoodMainList">
                            <OneArticle count={count} />
                        </Chip>
                    ) : (
                        ''
                    )}
                </div>
                {ad ? (
                    <div className={styles.ad}>
                        <Ad content={ad} />
                    </div>
                ) : (
                    ''
                )}
            </Fragment>
        );
    }
}

export default errorBoundary(Column);

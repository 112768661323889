import React from 'react';
import PropTypes from 'prop-types';
import Ad from '@ifeng-fe/ui_pc_ad';
import Chip from 'Chip';
import styles from './index.css';
import errorBoundary from '@ifeng-fe/errorBoundary';

/**
 * for this page
 */
import Slide from './slide';
import RightNews from './rightNews';
import Photoes from './photoes';
import Column from './column';
import CONFIG from './config';

// import adImg from '../../images/ad.png';

class Content extends React.PureComponent {
    static propTypes = {
        content: PropTypes.object,
    };
    /**
     * 渲染网页布局
     */
    render() {
        const {
            foodSlide,
            rightNews,
            leftNews,
            centerNews,
            foodResearchSlide,
            foodResearchList,
            foodReadyAfterSlide,
            foodReadyAfterList,
            foodHunterSlide,
            foodHunterList,
            foodCheersSlide,
            foodCheersList,
            foodRestaurantSlide,
            foodRestaurantList,
            column01,
            column02,
            column03,
            column04,
            rectangle01,
        } = this.props.content;

        // 处理Photoes数据
        if (Array.isArray(leftNews)) leftNews.splice(1, 0, ...centerNews);

        const columnData = {
            foodResearch: {
                tit: CONFIG.FOODSUBTITLE['foodResearch'],
                slide: foodResearchSlide,
                news: foodResearchList,
                ad: column02,
            },
            afterRead: {
                tit: CONFIG.FOODSUBTITLE['afterRead'],
                slide: foodReadyAfterSlide,
                news: foodReadyAfterList,
                ad: column03,
            },
            hunter: {
                tit: CONFIG.FOODSUBTITLE['hunter'],
                slide: foodHunterSlide,
                news: foodHunterList,
                ad: column04,
            },
            drank: {
                tit: CONFIG.FOODSUBTITLE['drank'],
                slide: foodCheersSlide,
                news: foodCheersList,
            },
            restaurant: {
                tit: CONFIG.FOODSUBTITLE['restaurant'],
                slide: foodRestaurantSlide,
                news: foodRestaurantList,
            },
        };

        return (
            <div className={styles.content}>
                <div className={styles.col_L}>
                    {/* 轮播图 */}
                    <Chip
                        id="75155"
                        type="recommend"
                        title="主焦点图"
                        groupName="正文"
                        content={foodSlide}
                        translate="handleFoodSlide">
                        <Slide />
                    </Chip>
                    <Photoes content={leftNews} ad={column01} />
                    <div className={styles.Ad}>
                        <Ad content={column01} />
                    </div>
                    {Object.keys(columnData).map(item => {
                        return <Column key={item} eachKey={item} content={columnData[item]} />;
                    })}
                </div>
                <div className={styles.col_R}>
                    <Chip
                        id="75158"
                        type="recommend"
                        title="右侧图文"
                        groupName="正文"
                        content={rightNews}
                        translate="handleFoodRightNews">
                        <RightNews />
                    </Chip>
                    <Ad content={rectangle01} />
                </div>
            </div>
        );
    }
}

export default errorBoundary(Content);

import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.css';

/**
 * 定义 Footer 组件
 */
class Footer extends React.PureComponent {
    static propTypes = {
        content: PropTypes.object,
    };

    /**
     * 渲染组件
     */
    render() {
        const { content, nav } = this.props.content;
        const copyrightData = content;

        return (
            <div className={styles.footer}>
                <div className={styles.foot_link}>
                    <div className={styles.fk_l}>
                        {nav.map((item, index) => (
                            <a key={index} className={styles.link} href={item.url} target="_blank">
                                {item.title}
                            </a>
                        ))}
                    </div>
                </div>
                <div className={styles.copyright}>
                    <span>{copyrightData.text0}</span>
                    <span>{copyrightData.text1}</span>
                    <span>{copyrightData.text2}</span>
                </div>
            </div>
        );
    }
}

export default Footer;

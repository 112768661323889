import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.css';
import errorBoundary from '@ifeng-fe/errorBoundary';

/**
 * for this page
 */

class RightNews extends React.PureComponent {
    static propTypes = {
        content: PropTypes.array,
    };

    render() {
        const { content } = this.props;

        const card = item => {
            return (
                <div key={item.id}>
                    <a href={item.url} target="_blank" alt={item.title} title={item.title}>
                        <img src={item.thumbnail} alt="" />
                        <p className={styles.tit}>{item.title}</p>
                    </a>
                </div>
            );
        };

        return (
            <div>
                {content.map(item => {
                    return card(item);
                })}
            </div>
        );
    }
}

export default errorBoundary(RightNews);

import { jsonp, ajax } from '@ifeng-fe/ui_base';

// apiUrl为webpack注入的全局变量
/* eslint-disable */
let apiBaseUrl = apiUrl;

/* eslint-enable */

if (typeof window !== 'undefined' && /\.shank\.ifeng\.com/.test(window.location.hostname)) {
    apiBaseUrl = '/api';
}

const md5 = require('md5');

const createJsonpCallbackName = (str, num) => {
    num = num ? num : 0;
    let jsonpCallbackName = `_${md5(`${str}_${num}`)}`;

    /* eslint-disable */
    while (typeof window === 'object' && window[jsonpCallbackName]) {
        /* eslint-enable */
        num++;
        jsonpCallbackName = `_${md5(`${str}_${num}`)}`;
    }

    return jsonpCallbackName;
};

const getUid = () => {
    return 'xxxxxxxxxxxx4xxxyxxxxxxxxxxxxxxx'.replace(/[xy]/g, c => {
        const r = (Math.random() * 16) | 0;
        const v = c === 'x' ? r : (r & 0x3) | 0x8;

        return v.toString(16);
    });
};

/**
 * 获取餐厅列表
 * @param {*} params { pageNumber, pageSize, type, area, searchValue }
 */
export const getRestaurantList = async params => {
    return await ajax(`${apiBaseUrl}/getRestaurantList/${getUid()}`, {
        data: params,
        type: 'post',
        cache: false,
    });
};

export const getRestaurantList2021 = async params => {
    return await ajax(`${apiBaseUrl}/getRestaurantList2021/${getUid()}`, {
        data: params,
        type: 'post',
        cache: false,
    });
};

export const getRestaurantInfo = async id => {
    return await jsonp(`${apiBaseUrl}/getGourmetFestivalDocData/${id}/getRestaurantInfo`, {
        jsonpCallback: 'getRestaurantInfo',
        cache: false,
    });
};

/**
 * 提交调查
 * @param {String} id 调查id
 * @param {String} sur 选项拼接字符串
 */
export const postSurvey = async (id, sur) => {
    try {
        const data = await jsonp(`//sv.ifeng.com/index.php/survey/postsurvey?${sur}`, {
            data: { id },
            jsonpCallback: 'callback',
            cache: false,
        });

        return data;
    } catch (e) {
        console.error(e);
    }
};

// 获取文章评论数
export const getCommentCount = async url => {
    return await jsonp('//comment.ifeng.com/get.php', {
        data: {
            job: 4,
            format: 'js',
            callback: 'getAllComment1',
            docurl: url.join('|'),
        },
        jsonCallback: 'getAllComment1',
        cache: false,
    });
};

// 获取调查数据
export const getSurveyData = async (id, index = 0) => {
    const { schemaCheck } = await import('./validate');

    let data = await jsonp('//sv.ifeng.com/index.php/survey/getdata', {
        data: {
            id,
        },
        jsonpCallback: createJsonpCallbackName(id, index),
        cache: false,
    });
    const requestUrl = `sv.ifeng.com/index.php/survey/getdata?id=${id}&callback=getSurveyData`;

    data = schemaCheck(data, 'surveyDataSchema', requestUrl);

    return data ? data : null;
};

export const getiVideoData = async id => {
    const jsonpCallback = createJsonpCallbackName(id, new Date().getTime());

    return await jsonp(`${apiBaseUrl}/getVideoData/${id}/${jsonpCallback}`, {
        jsonpCallback,
        cache: false,
    });
};

import React from 'react';
import PropTypes from 'prop-types';
import Ad from '@ifeng-fe/ui_pc_ad';
import Chip from 'Chip';
import styles from './index.css';
import errorBoundary from '@ifeng-fe/errorBoundary';

class Photoes extends React.PureComponent {
    static propTypes = {
        content: PropTypes.array,
    };

    render() {
        const { content } = this.props;

        return (
            <div className={`${styles.con} clearfix`}>
                <div className={styles.con_l}>
                    {Array.isArray(content) &&
                        content.map((item, index) => (
                            <Chip
                                key={index}
                                id={index === 1 ? '75157' : '75156'}
                                type="recommend"
                                title={index === 1 ? '中间图文' : '左侧图文'}
                                groupName="正文"
                                content={[item]}
                                translate={index === 1 ? 'handleFoodCenterNews' : 'handleFoodLeftNews'}>
                                <div className={index === 1 ? styles.con_r : styles.con_l_t}>
                                    <a href={item.url} target="_blank" alt={item.title} title={item.title}>
                                        <img src={item.thumbnail} alt="" />
                                        <p>{item.title}</p>
                                    </a>
                                </div>
                            </Chip>
                        ))}
                </div>
            </div>
        );
    }
}

export default errorBoundary(Photoes);

import React from 'react';
import { formatImage } from '@ifeng-fe/public_method';
import PropTypes from 'prop-types';
import styles from './index.css';
import errorBoundary from '@ifeng-fe/errorBoundary';

class OneArticle extends React.PureComponent {
    static propTypes = {
        content: PropTypes.array,
        count: PropTypes.array,
    };

    render() {
        const { content, count } = this.props;

        return (
            <div>
                {Array.isArray(content) &&
                    content.map((item, index) => (
                        <a
                            key={item.id}
                            href={item.url}
                            target="_blank"
                            alt={item.title}
                            title={item.title}
                            className={
                                index === content.length - 1 ? `${styles.con} ${styles.borderNone}` : styles.con
                            }>
                            <div className={item.thumbnail ? styles.con_l : ''}>
                                <img src={formatImage(item.thumbnail, 310, 232)} alt="" />
                            </div>
                            <div className={item.thumbnail ? styles.con_r : styles.con_no_img}>
                                <h3 className={styles.tit}>{item.title}</h3>
                                <p className={styles.intro}>{item.summary}</p>
                                <div className={item.thumbnail ? styles.mesg : styles.mesg_no_img}>
                                    <p className={styles.author}>
                                        {item.source} {item.newsTime}
                                    </p>
                                    <p className={styles.cmt}>{count[index] > 0 ? count[index] : ''}</p>
                                </div>
                            </div>
                        </a>
                    ))}
            </div>
        );
    }
}

export default errorBoundary(OneArticle);

import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.css';
import Ad from '@ifeng-fe/ui_pc_ad';
import Chip from 'Chip';
import ChipEdit from 'ChipEdit';
import transform from 'chipDataTransform';
import errorBoundary from '@ifeng-fe/errorBoundary';
import Header from './header';
import Navigation from './navigation';
import Content from './content';
import Footer from '../components/footer';
import InnerHtml from '../components/innerHtml';

class Layout extends React.PureComponent {
    static propTypes = {
        content: PropTypes.object,
    };

    componentDidMount() {
        // 上报首屏时间
        if (window && window.BJ_REPORT) window.BJ_REPORT.firstScreen();
    }

    render() {
        /**
         * 组件分发数据
         */
        const {
            content: {
                nav,
                footer,
                search,
                logo,
                logoAd,
                navigation,
                foodSlide,
                rightNews,
                leftNews,
                centerNews,
                foodResearchSlide,
                foodResearchList,
                foodReadyAfterSlide,
                foodReadyAfterList,
                foodHunterSlide,
                foodHunterList,
                foodCheersSlide,
                foodCheersList,
                foodRestaurantSlide,
                foodRestaurantList,
                cooperation,
                topAd,
                floatAd,
                column01,
                column02,
                column03,
                column04,
                rectangle01,
                bottomAd,
            },
        } = this.props;

        const headerData = {
            nav,
            search,
            topAd,
            logo,
            logoAd,
        };

        const content = {
            foodSlide,
            rightNews,
            leftNews,
            centerNews,
            foodResearchSlide,
            foodResearchList,
            foodReadyAfterSlide,
            foodReadyAfterList,
            foodHunterSlide,
            foodHunterList,
            foodCheersSlide,
            foodCheersList,
            foodRestaurantSlide,
            foodRestaurantList,
            column01,
            column02,
            column03,
            column04,
            rectangle01,
        };

        return (
            <div className={styles.layout}>
                <Header content={headerData} />
                <Chip id="50129" type="struct" title="顶部导航" groupName="导航栏" content={navigation}>
                    <Navigation />
                </Chip>

                <Content content={content} />

                {/* 浮动对联广告 */}
                <div className={styles.fl}>
                    <Ad content={floatAd} />
                </div>
                <div className={styles.fr}>
                    <Ad content={floatAd} />
                </div>

                <Chip id="40099" type="static" title="底部合作" groupName="content" content={cooperation}>
                    <InnerHtml />
                </Chip>

                {/* 底部广告 */}
                <div className={styles.bottomAd}>
                    <Ad content={bottomAd} />
                </div>

                <Chip id="20036" type="struct" title="底部公用版权" groupName="底部" content={footer}>
                    <Footer />
                </Chip>
                <ChipEdit transform={transform} />
            </div>
        );
    }
}

export default errorBoundary(Layout);

export default {
    FOODSUBTITLE: {
        foodResearch: '美食研究所',
        afterRead: '阅后即食',
        hunter: '风味猎人',
        drank: '举杯莫停',
        restaurant: '凤凰餐厅指南',
    },
    MAINSILDECHIPID: {
        foodResearch: { id: '75159', title: '美食研究所轮播图' },
        afterRead: { id: '75161', title: '阅后即食轮播图' },
        hunter: { id: '75163', title: '风味猎人轮播图' },
        drank: { id: '75165', title: '举杯莫停轮播图' },
        restaurant: { id: '75210', title: '凤凰餐厅指南轮播图' },
    },
    MAINLISTCHIPID: {
        foodResearch: { id: '75160', title: '美食研究所列表' },
        afterRead: { id: '75162', title: '阅后即食列表' },
        hunter: { id: '75164', title: '风味猎人列表' },
        drank: { id: '75166', title: '举杯莫停列表' },
        restaurant: { id: '75211', title: '凤凰餐厅指南列表' },
    },
};

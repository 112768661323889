import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.css';

const Tit = ({ txt }) => (
    <div className={`${styles.tit} clearfix`}>
        <div className={styles.red} />
        <div className={styles.txt}>{txt}</div>
    </div>
);

Tit.propTypes = {
    txt: PropTypes.string,
};

export default Tit;
